/*
This is a replacement for our "static" sentry as used in openly
See https://docs.sentry.io/platforms/javascript/
*/
import * as Sentry from "@sentry/browser";
import {BrowserTracing} from "@sentry/tracing";
if (typeof SENTRY_SDK_DSN !== "undefined") {
    Sentry.init({
        /*
        This is a replacement for our "static" sentry as used in openly
        See https://docs.sentry.io/platforms/javascript/
        */
        dsn: SENTRY_SDK_DSN,
        // Alternatively, use `process.env.npm_package_version` for a dynamic release version
        // if your build tool supports it.
        // release: "my-project-name@2.3.12",
        integrations: [new BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    });
    Sentry.setUser({username: window.__sentry_username__});
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
        console.debug("current username:", window.__sentry_username__);
    }
}
